import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SectionTitle } from 'core/components/SectionTitle'
import schema, {
  CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_FORMAL,
  CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_INTERNAL,
  CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_SUBSTANTIVE,
} from '_schema/contestProposalRateGuidelines'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { ItemRate } from './Item'

export const Collection = ({ resource, definitions, disabled }) => {
  const [collection, setCollection] = useState([
    ...resource.contestProposalRateGuidelines,
  ])

  const handlePlusClick = type => {
    const url = schema(type, resource['@id']).endpoint
    fetchDataHandleAuthError(
      url,
      'POST',
      {
        body: JSON.stringify({
          title: '',
          description: '',
          pointsMin: 0,
          pointsMax: 0,
          type: type,
          contest: resource['@id'],
        }),
      },
      resp => {
        setCollection(prevState => [...prevState, resp])
        notification('success', 'Dodano nową wytyczną')
      },
      error => {
        notification('error', error.response.detail)
      }
    )
  }

  const handleRemove = uuid => {
    const array = collection.filter(el => el.uuid !== uuid)
    setCollection([])
    setCollection(array)
  }

  let count = 0

  return (
    <>
      <SectionTitle label={'Ocena merytoryczna'} divider={true} />
      {collection.map((el, index) => {
        if (index === 0) {
          count = 0
        }
        if (el.type === CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_SUBSTANTIVE) {
          count += 1
          return (
            <ItemRate
              key={index}
              index={count}
              schema={schema(CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_SUBSTANTIVE)}
              el={el}
              onRemove={handleRemove}
              definitions={definitions}
              disabled={disabled}
            />
          )
        } else {
          return null
        }
      })}
      <ButtonPlus
        text={'Dodaj wytyczną merytoryczną'}
        onClick={() =>
          handlePlusClick(CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_SUBSTANTIVE)
        }
        disabled={disabled}
      />
      <SectionTitle label={'Ocena formalna'} divider={true} />
      {collection.map((el, index) => {
        if (index === 0) {
          count = 0
        }
        if (el.type === CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_FORMAL) {
          count += 1
          return (
            <ItemRate
              key={index}
              index={count}
              schema={schema(CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_FORMAL)}
              el={el}
              onRemove={handleRemove}
              definitions={definitions}
              disabled={disabled}
            />
          )
        } else {
          return null
        }
      })}
      <ButtonPlus
        text={'Dodaj wytyczną formalną'}
        onClick={() =>
          handlePlusClick(CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_FORMAL)
        }
        disabled={disabled}
      />
      <SectionTitle label={'Ocena wewnętrzna'} divider={true} />
      {collection.map((el, index) => {
        if (index === 0) {
          count = 0
        }
        if (el.type === CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_INTERNAL) {
          count += 1
          return (
            <ItemRate
              key={index}
              index={count}
              schema={schema(CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_INTERNAL)}
              el={el}
              onRemove={handleRemove}
              definitions={definitions}
              disabled={disabled}
            />
          )
        } else {
          return null
        }
      })}
      <ButtonPlus
        text={'Dodaj wytyczną wewnętrzną'}
        onClick={() =>
          handlePlusClick(CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_INTERNAL)
        }
        disabled={disabled}
      />
    </>
  )
}

Collection.propTypes = {
  resource: PropTypes.object,
  definitions: PropTypes.object,
}
