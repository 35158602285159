import React, { useMemo, useReducer, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import _ from 'lodash'
import { Warning } from '@material-ui/icons'
import { Loader } from 'core/components/Loader'
import { Title } from 'core/components/Title'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { isObjectEmpty } from 'core/_helpers/isObjectEmpty'
import { createObjectFromString } from 'core/_helpers/createObjectFromString'
import { SubmitButton, CancelButton, AcceptButton } from './buttons'
import { initState } from './_helpers/initState'
import { FieldError } from './_helpers/fieldError'
import { fields } from './fields'
import { constants, reducer } from './_state'
import { propTypes, useStyles } from '.'
import { Grid, Typography } from '@material-ui/core'
import { SectionTitle } from '../SectionTitle'

function FieldGlobalComponent(
  property,
  name,
  defaultClasses,
  classes,
  state,
  method,
  resource,
  readOnly,
  disabled,
  setValue,
  setError,
  setAdditional,
  fieldsFullWidth,
  fieldNodeRefs
) {
  if (typeof property.type === 'string' && !fields[property.type]) {
    throw new FieldError(property.type)
  }

  const FieldComponent =
    typeof property.type === 'string' ? fields[property.type] : property.type

  const {
    type,
    description,
    hint,
    validate,
    items,
    disabled: fieldDisabled,
    width,
    condition,
    placeholder,
    minDate,
    maxDate,
    ...rest
  } = property

  return (
    <div key={name} className={clsx(defaultClasses.field, classes.field)}>
      {(condition === undefined ||
        state.values[condition.name] === condition.value) && (
        <FieldComponent
          uuid={state.values[process.env.REACT_APP_RESOURCE_ID]}
          formUrl={state.url}
          formMethod={method}
          name={name}
          type={type}
          label={description}
          hint={hint}
          initialValue={
            resource?.[name] !== undefined
              ? resource[name]
              : property.defaultValue !== undefined
              ? property.defaultValue
              : null
          }
          value={state.values[name]}
          compareValue={state.compareValues?.[name]}
          compare={!!state.compareValues}
          error={state.errors[name]}
          renderError={state.renderError[name]}
          disabled={readOnly || fieldDisabled || disabled || state.isProcessing}
          validators={validate}
          setValue={setValue}
          setError={setError}
          additional={state.additional}
          setAdditional={setAdditional}
          fullWidth={fieldsFullWidth}
          formWidth={width}
          width={width}
          definitionRef={items?.$ref}
          nodeRef={fieldNodeRefs[name] || null}
          placeholder={placeholder}
          minDate={property.minDate ? state.values[property.minDate] : null}
          maxDate={property.maxDate ? state.values[property.maxDate] : null}
          {...rest}
        />
      )}
    </div>
  )
}

export const Form = ({
  readOnly = false,
  title = null,
  url,
  method = 'PUT',
  properties,
  resource: rawResource = null,
  defaultData = {},
  handleSubmit: customHandleSubmit = null,
  handleSuccess = null,
  handleSuccessAndStay = null,
  handleCancel = null,
  showSubmitAndStayButton = true,
  showCancelButton = true,
  disabled = false,
  fieldsFullWidth = false,
  width = 300,
  classes = {},
  children = null,
  buttonContainerRef = null,
  fieldNodeRefs = {},
  buttonsFixed = false,
  fetchCompareResource = false,
  submitButtonLabel = translate('T_FORM_SAVE'),
  submitAndStayButtonLabel = translate('T_FORM_SAVE_AND_STAY'),
  showSubmitButton = true,
  isChange = () => false,
  subForm = null,
  saveForm = false,
  setSaveForm = null,
  buttonSubmitDisabled = false,
  loaderAlign = 'left',
  customBeforeSubmit = null,
}) => {
  const resource = useMemo(() => {
    if (!rawResource) {
      return null
    }

    const modifiedResource = { ...rawResource }

    if (
      properties?.stat === undefined &&
      modifiedResource?.stat !== undefined
    ) {
      delete modifiedResource.stat
    }

    return modifiedResource
  }, [rawResource, properties])

  const [state, dispatch] = useReducer(
    reducer,
    {
      properties,
      resource,
      defaultData,
      url,
      method,
      fetchCompareResource:
        !readOnly &&
        fetchCompareResource &&
        ['PUT', 'PATCH'].includes(method) &&
        rawResource?.updatedAt !== undefined,
    },
    initState
  )

  const setValue = useCallback(
    (name, value, setRenderError = true) => {
      isChange()
      dispatch({
        type: constants.SET_VALUE,
        payload: { name, value, setRenderError },
      })
    },
    [isChange]
  )

  const setError = useCallback((name, error) => {
    dispatch({ type: constants.SET_ERROR, payload: { name, error } })
  }, [])

  const setAdditional = useCallback(additional => {
    dispatch({ type: constants.SET_ADDITIONAL, payload: { additional } })
  }, [])

  useEffect(() => {
    if (!state.isSubmitted) {
      return
    }

    dispatch({ type: constants.RENDER_ERROR })
  }, [state.isSubmitted])

  const handleSubmitButton = e => {
    e.preventDefault()
    handleSubmit(handleSuccess)
  }
  const handleSubmitAndStayButton = e => {
    e.preventDefault()
    handleSubmit(handleSuccessAndStay)
  }

  const handleAcceptButton = e => {
    e.preventDefault()
    dispatch({ type: constants.REMOVE_COMPARE_RESOURCE })
  }

  const performSubmit = useCallback(handleSuccess => {
    if (customHandleSubmit) {
      customHandleSubmit(state, dispatch)

      return
    }

    dispatch({ type: constants.PROCESS, payload: true })

    let body = state.values

    if (customBeforeSubmit) {
      body = customBeforeSubmit(body)
    }

    fetchDataHandleAuthError(
      state.url,
      method,
      { body: JSON.stringify(body) },
      response => {
        if (properties?.stat === undefined && response?.stat !== undefined) {
          delete response.stat
        }

        dispatch({
          type: constants.SUCCESS,
          payload: {
            method,
            resource: response,
          },
        })
        notification(
          'success',
          ['PUT', 'PATCH'].includes(method)
            ? 'T_FORM_RECORD_UPDATED'
            : 'T_FORM_RECORD_CREATED',
          'T_FORM_SUCCESS'
        )
        handleSuccess && handleSuccess(response)
      },
      error => {
        const errors = error.response.violations.reduce(
          (processedErrors, item) => {
            const processedError = createObjectFromString(
              item.propertyPath.replace('[', '.').replace(']', ''),
              item.message
            )

            return _.merge(processedErrors, processedError)
          },
          {}
        )

        dispatch(
          isObjectEmpty(errors)
            ? { type: constants.PROCESS, payload: false }
            : { type: constants.FAILURE, payload: { errors } }
        )

        notification(
          'error',
          error.response.violations.length
            ? 'T_FORM_INCORRECT'
            : error.response.detail,
          error.response.title
        )
      },
      {}
    )
  }) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = useCallback(
    handleSuccess => {
      if (readOnly) {
        return
      }

      dispatch({ type: constants.SUBMIT })

      if (state.isInvalid) {
        return
      }

      if (!state.fetchCompareResource) {
        performSubmit(handleSuccess)

        return
      }

      dispatch({ type: constants.PROCESS, payload: true })

      fetchDataHandleAuthError(
        state.url,
        'GET',
        {},
        response => {
          if (
            response.updatedAt === undefined ||
            response.updatedAt === state.values.updatedAt
          ) {
            performSubmit(handleSuccess)

            return
          }

          dispatch({
            type: constants.SET_COMPARE_RESOURCE,
            payload: response,
          })
        },
        error => {
          dispatch({ type: constants.PROCESS, payload: false })

          notification('error', error.response.detail, error.response.title)
        },
        {}
      )
    },
    [
      readOnly,
      state.isInvalid,
      state.fetchCompareResource,
      state.url,
      state.values.updatedAt,
      performSubmit,
    ]
  )

  useEffect(() => {
    if (saveForm) {
      setSaveForm()
      handleSubmit(handleSuccessAndStay)
    }
  }, [handleSuccessAndStay, handleSubmit, saveForm, setSaveForm])

  const defaultClasses = useStyles()
  const isSidebarOpen = false

  const handleSubmitFormReset = e => {
    e.preventDefault()
    return false
  }

  const publicationStatusFromRedux = useSelector(
    state => state.common.currentResource?.publicationStatus
  )

  return (
    <div>
      {title && <Title>{translate(title)}</Title>}
      {state.isProcessing && <Loader align={loaderAlign} />}
      {state.compareValues && (
        <div className={defaultClasses.recordChanged}>
          <Warning /> <span>{translate('T_FORM_RECORD_CHANGED')}</span>
        </div>
      )}
      <form
        className={clsx(defaultClasses.root, classes.root)}
        style={{
          width,
        }}
        onSubmit={handleSubmitFormReset}
      >
        {children &&
          children({
            disabled: state.isProcessing,
            submitted: state.isSubmitted,
            setValue,
            setError,
            setAdditional,
          })}
        {Object.keys(properties).map(name => {
          if (properties[name].type === 'sectionTitle') {
            return (
              <SectionTitle
                key={name}
                label={properties[name].title}
                divider={properties[name].divider}
              />
            )
          } else if (properties[name].type === 'column') {
            return (
              <Grid
                container
                spacing={3}
                key={name}
                style={{ marginBottom: `${properties[name].bottom || 0}px` }}
              >
                {Object.keys(properties[name].properties).map(subname => {
                  return (
                    <Grid
                      key={subname}
                      xs={12}
                      lg={6}
                      xl={4}
                      item
                      style={{ width: '100%' }}
                    >
                      <div className={defaultClasses.columnFields}>
                        <Typography
                          color="primary"
                          variant={'subtitle1'}
                          className={defaultClasses.columnFieldsTitle}
                        >
                          {properties[name].properties[subname].label}
                        </Typography>
                        <Grid container spacing={3} style={{ width: '100%' }}>
                          {Object.keys(
                            properties[name].properties[subname].properties
                          ).map(sub => (
                            <Grid item xs key={sub}>
                              {FieldGlobalComponent(
                                properties[name].properties[subname].properties[
                                  sub
                                ],
                                sub,
                                defaultClasses,
                                classes,
                                state,
                                method,
                                resource,
                                readOnly,
                                (disabled =
                                  properties[name].properties[subname]
                                    ?.disabledByStatus &&
                                  publicationStatusFromRedux !== 'DRAFT'),
                                setValue,
                                setError,
                                setAdditional,
                                fieldsFullWidth,
                                fieldNodeRefs
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
            )
          } else if (properties[name].type === 'group') {
            return (
              <Grid
                container
                spacing={3}
                key={name}
                style={{
                  maxWidth: properties[name].width
                    ? properties[name].width
                    : '100%',
                }}
              >
                {Object.keys(properties[name].properties).map(subname => {
                  const { column } = properties[name].properties[subname]

                  return (
                    <Grid
                      item
                      md={column}
                      key={subname}
                      className={clsx(defaultClasses.field, classes.field)}
                    >
                      {FieldGlobalComponent(
                        properties[name].properties[subname],
                        subname,
                        defaultClasses,
                        classes,
                        state,
                        method,
                        resource,
                        readOnly,
                        (disabled =
                          properties[name].properties[subname]
                            ?.disabledByStatus &&
                          publicationStatusFromRedux !== 'DRAFT'),
                        setValue,
                        setError,
                        setAdditional,
                        fieldsFullWidth,
                        fieldNodeRefs
                      )}
                    </Grid>
                  )
                })}
              </Grid>
            )
          } else if (properties[name].type === 'krstype') {
            const FieldComponent = fields[properties[name].type]
            const {
              type,
              description,
              hint,
              validate,
              items,
              disabled: fieldDisabled,
              ...rest
            } = properties[name]
            return (
              <div
                key={name}
                className={clsx(defaultClasses.field, classes.field)}
              >
                <FieldComponent
                  values={properties[name].values}
                  setValue={setValue}
                  setError={setError}
                  error={
                    state.errors[properties[name].values[0]] ||
                    state.errors[properties[name].values[1]] ||
                    state.errors[properties[name].values[2]]
                  }
                  renderError={
                    state.renderError[properties[name].values[0]] ||
                    state.renderError[properties[name].values[1]] ||
                    state.renderError[properties[name].values[2]]
                  }
                  {...rest}
                />
              </div>
            )
          } else if (properties[name].type === 'subform') {
            return subForm(resource)[properties[name].property] ? (
              <div key={name}>
                {subForm(resource)[properties[name].property].component}
              </div>
            ) : null
          } else {
            return FieldGlobalComponent(
              properties[name],
              name,
              defaultClasses,
              classes,
              state,
              method,
              resource,
              readOnly,
              (disabled =
                (properties[name]?.disabledByStatus &&
                  publicationStatusFromRedux !== 'DRAFT') ||
                (publicationStatusFromRedux !== 'DRAFT' &&
                  properties[name]?.disabledByStatusIfNotNull &&
                  !!resource[name])),
              setValue,
              setError,
              setAdditional,
              fieldsFullWidth,
              fieldNodeRefs
            )
          }
        })}
        {!readOnly && (
          <div
            className={
              !buttonContainerRef
                ? clsx(
                    buttonsFixed && defaultClasses.buttonsFixed,
                    isSidebarOpen && defaultClasses.buttonsFixedOpen,
                    !buttonsFixed && defaultClasses.buttonsNotFixed,
                    classes.buttons
                  )
                : null
            }
          >
            {state.compareValues && (
              <>
                <AcceptButton
                  handleAccept={handleAcceptButton}
                  disabled={
                    disabled ||
                    state.isProcessing ||
                    (state.isSubmitted && state.isInvalid)
                  }
                  classes={{ submit: classes.submit }}
                  nodeRef={buttonContainerRef}
                />
              </>
            )}
            {!state.compareValues && (
              <>
                {(typeof showSubmitButton === 'function'
                  ? showSubmitButton(rawResource)
                  : showSubmitButton) && (
                  <SubmitButton
                    title={submitButtonLabel}
                    handleSubmit={handleSubmitButton}
                    disabled={
                      disabled ||
                      state.isProcessing ||
                      (state.isSubmitted && state.isInvalid)
                    }
                    classes={{ submit: classes.submit }}
                    nodeRef={buttonContainerRef}
                  />
                )}
                {showSubmitAndStayButton && (
                  <SubmitButton
                    title={submitAndStayButtonLabel}
                    handleSubmit={handleSubmitAndStayButton}
                    disabled={
                      buttonSubmitDisabled ||
                      disabled ||
                      state.isProcessing ||
                      (state.isSubmitted && state.isInvalid)
                    }
                    variant={showCancelButton ? 'contained' : 'outlined'}
                    classes={{ submit: classes.submit }}
                    nodeRef={buttonContainerRef}
                  />
                )}
              </>
            )}
            {showCancelButton && (
              <CancelButton
                handleCancel={handleCancel}
                disabled={
                  disabled ||
                  state.isProcessing ||
                  (state.isSubmitted && state.isInvalid)
                }
                nodeRef={buttonContainerRef}
              />
            )}
          </div>
        )}
      </form>
    </div>
  )
}

Form.propTypes = propTypes
