import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { BlankForm } from './BlankForm'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { Sidebar as OriginalSidebar } from 'core/components/resource/sidebar'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { commonConstants } from 'core/_constants'
import { NotFound } from 'core/pages/NotFound'
import { translate } from 'core/_helpers/translate'
import { boolean } from 'core/_helpers/boolean'
import { CollectionHeader } from '../components/CollectionHeader'
import { FormWithStepper } from './FormWithStepper'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  grid: {
    marginBottom: 70,
    padding: theme.spacing(4),
  },
  sidebar: {
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    position: 'sticky',
    top: theme.spacing(13),
  },
}))

export const Form = ({
  iri = null,
  isNotEditable: verifyIsNotEditable = null,
  collectionPath = null,
  editPath = null,
  storeCollectionId = null,
  isCurrentResource = true,
  wrapWithPaper = true,
  header = null,
  footer = null,
  sidebar = false,
  sidebarTitleAccessor = 'title',
  sidebarStatAccessor = 'stat',
  SidebarComponent = null,
  children = null,
  customResourcesSchemas = {},
  sidebarDeleteText = '',
  buttonSaveInSidebar = false,
  buttonsFixed = true,
  subForm = null,
  tab = null,
  sidebarWidth = 3,
  fetchCompareResource = boolean(
    process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
  ),
  sidebarStatusIsSwitch = false,
  statusTitle = null,
  sidebarUrl = null,
  sidebarBottomContainer = null,
  sidebarDeletable = true,
  sidebarStatable = true,
  submitAndStayButtonLabel = null,
  submitButtonLabel = null,
  buttonSubmitDisabled = false,
  saveStepperDisabled = false,
  sidebarButtonInline = false,
  preview = false,
  prevurl = '',
  handleResourceChange = null,
  readOnly = false,
  showSubmitButton = true,
  ...rest
}) => {
  const [state, setState] = useResourceState()

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      isCurrentResource &&
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [isCurrentResource, dispatch]
  )

  const { resource, isFetching, isNotEditable, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    verifyIsNotEditable,
    setState.isNotEditable,
    dispatchOnFetch
  )

  useEffect(() => {
    if (resource && isCurrentResource && isNotEditable) {
      dispatch({ type: commonConstants.RESET_CURRENT_RESOURCES })
    }
  }, [resource, isCurrentResource, isNotEditable, dispatch])

  useEffect(() => {
    if (typeof handleResourceChange === 'function' && resource) {
      handleResourceChange(resource)
    }
  }, [handleResourceChange, resource])

  const calculatedCollectionPath = useMemo(
    () =>
      collectionPath
        ? typeof collectionPath === 'function'
          ? resource
            ? collectionPath(resource)
            : null
          : collectionPath
        : null,
    [collectionPath, resource]
  )

  const calculatedStoreCollectionId = useMemo(
    () =>
      storeCollectionId
        ? typeof storeCollectionId === 'function'
          ? resource
            ? storeCollectionId(resource)
            : null
          : storeCollectionId
        : null,
    [storeCollectionId, resource]
  )

  const WrapComponent = wrapWithPaper ? Paper : 'div'
  const Sidebar = SidebarComponent || OriginalSidebar

  const classes = useStyles()

  const [buttonRef, setButtonRef] = useState(null)

  const onButtonRef = element => {
    setButtonRef(element)
  }

  return isFetching ? (
    <WrapComponent>
      <Loader />
    </WrapComponent>
  ) : isNotEditable ? (
    <Redirect to={calculatedCollectionPath} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={sidebar ? 12 - sidebarWidth : 12}>
          {header ? (
            <>{typeof header === 'function' ? header(resource) : header}</>
          ) : (
            <CollectionHeader hideButton buttonTitle="" buttonPath="">
              {translate(iri ? 'T_GENERAL_EDIT' : 'T_GENERAL_NEW')}
            </CollectionHeader>
          )}
          {Object.keys(customResourcesSchemas).length === 0 ? (
            <div className={clsx(sidebar && classes.grid)}>
              <Wrapper wrapWithPaper={false}>
                <BlankForm
                  resource={resource}
                  iri={iri}
                  collectionPath={calculatedCollectionPath}
                  editPath={editPath}
                  storeCollectionId={calculatedStoreCollectionId}
                  isCurrentResource={isCurrentResource}
                  buttonsFixed={buttonsFixed}
                  buttonContainerRef={buttonSaveInSidebar ? buttonRef : null}
                  fieldsFullWidth={true}
                  fetchCompareResource={fetchCompareResource}
                  showSubmitButton={showSubmitButton}
                  submitButtonLabel={
                    typeof submitButtonLabel === 'function'
                      ? submitButtonLabel(resource)
                      : submitButtonLabel
                  }
                  submitAndStayButtonLabel={
                    typeof submitAndStayButtonLabel === 'function'
                      ? submitAndStayButtonLabel(resource)
                      : submitAndStayButtonLabel
                  }
                  {...rest}
                />
                {typeof children === 'function' ? children(resource) : children}
              </Wrapper>
            </div>
          ) : (
            <div className={clsx(sidebar && classes.grid)}>
              <Wrapper wrapWithPaper={false}>
                <FormWithStepper
                  subForm={subForm}
                  tab={tab}
                  resource={resource}
                  iri={iri}
                  collectionPath={calculatedCollectionPath}
                  editPath={editPath}
                  storeCollectionId={calculatedStoreCollectionId}
                  isCurrentResource={isCurrentResource}
                  buttonsFixed={buttonsFixed}
                  customResourcesSchemas={customResourcesSchemas}
                  buttonContainerRef={buttonSaveInSidebar ? buttonRef : null}
                  fieldsFullWidth={true}
                  setResource={res => setState.resource(res)}
                  fetchCompareResource={fetchCompareResource}
                  buttonSubmitDisabled={buttonSubmitDisabled}
                  saveStepperDisabled={saveStepperDisabled}
                  readOnly={readOnly}
                  showSubmitButton={showSubmitButton}
                  submitAndStayButtonLabel={
                    typeof submitAndStayButtonLabel === 'function'
                      ? submitAndStayButtonLabel(resource)
                      : submitAndStayButtonLabel
                  }
                  {...rest}
                />
              </Wrapper>
            </div>
          )}
        </Grid>
        {sidebar && (
          <Grid item xs={12} md={sidebarWidth}>
            <Wrapper wrapWithPaper={wrapWithPaper} className={classes.sidebar}>
              <Sidebar
                resource={resource}
                titleAccessor={sidebarTitleAccessor}
                statAccessor={sidebarStatAccessor}
                collectionPath={calculatedCollectionPath}
                deleteText={sidebarDeleteText}
                sidebarButtonInline={sidebarButtonInline}
                preview={preview}
                prevurl={prevurl}
                setResource={res => setState.resource(res)}
                statable={
                  typeof sidebarStatable === 'function'
                    ? sidebarStatable(resource)
                    : sidebarStatable
                }
                deletable={
                  typeof sidebarDeletable === 'function'
                    ? sidebarDeletable(resource)
                    : sidebarDeletable
                }
                onButtonSaveRef={
                  buttonSaveInSidebar ? onButtonRef : () => false
                }
                sidebarStatusIsSwitch={sidebarStatusIsSwitch}
                statusTitle={statusTitle}
                sidebarUrl={sidebarUrl}
                dispatch={dispatch}
              />
              {typeof sidebarBottomContainer === 'function'
                ? sidebarBottomContainer(resource)
                : sidebarBottomContainer}
            </Wrapper>
          </Grid>
        )}
      </Grid>
      <>{typeof footer === 'function' ? footer(resource) : footer}</>
    </div>
  )
}

const Wrapper = ({ children, wrapWithPaper, className }) =>
  wrapWithPaper ? (
    <Paper className={className} withPadding={false}>
      {children}
    </Paper>
  ) : (
    <div className={className}>{children}</div>
  )

Form.propTypes = {
  iri: PropTypes.string,
  isNotEditable: PropTypes.func,
  collectionPath: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  editPath: PropTypes.string,
  storeCollectionId: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isCurrentResource: PropTypes.bool,
  wrapWithPaper: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  sidebar: PropTypes.bool,
  sidebarTitleAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  sidebarStatAccessor: PropTypes.string,
  SidebarComponent: PropTypes.elementType,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  sidebarBottomContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  customResourcesSchemas: PropTypes.array,
  sidebarDeleteText: PropTypes.string,
  buttonSaveInSidebar: PropTypes.bool,
  subForm: PropTypes.func,
  tab: PropTypes.number,
  buttonsFixed: PropTypes.bool,
  sidebarWidth: PropTypes.number,
  sidebarStatusIsSwitch: PropTypes.bool,
  statusTitle: PropTypes.string,
  sidebarUrl: PropTypes.string,
  sidebarDeletable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  sidebarStatable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  submitAndStayButtonLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  buttonSubmitDisabled: PropTypes.bool,
  saveStepperDisabled: PropTypes.bool,
  sidebarButtonInline: PropTypes.bool,
  preview: PropTypes.bool,
  prevurl: PropTypes.string,
}
