import PropTypes from 'prop-types'

export const propTypes = {
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  properties: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
        .isRequired,
      properties: PropTypes.object,
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }),
      ]).isRequired,
      hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      validate: PropTypes.arrayOf(PropTypes.string),
      endpoint: PropTypes.string,
      thumbs: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
          endpoint: PropTypes.string.isRequired,
        }),
      ]),
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          '@id': PropTypes.string.isRequired,
        })
      ),
      choices: PropTypes.object,
      titleAccessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.func,
      ]),
    })
  ).isRequired,
  resource: PropTypes.object,
  defaultData: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleSuccess: PropTypes.func,
  handleSuccessAndStay: PropTypes.func,
  handleCancel: PropTypes.func,
  showSubmitAndStayButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldsFullWidth: PropTypes.bool,
  width: PropTypes.number,
  classes: PropTypes.shape({
    root: PropTypes.string,
    field: PropTypes.string,
    buttons: PropTypes.string,
    submit: PropTypes.string,
  }),
  children: PropTypes.func,
  buttonContainerRef: PropTypes.object,
  fieldNodeRefs: PropTypes.object,
  buttonsFixed: PropTypes.bool,
  fetchCompareResource: PropTypes.bool,
  submitButtonLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  showSubmitButton: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  submitAndStayButtonLabel: PropTypes.string,
  isChange: PropTypes.func,
  subForm: PropTypes.func,
  saveForm: PropTypes.bool,
  setSaveForm: PropTypes.func,
  buttonSubmitDisabled: PropTypes.bool,
  loaderAlign: PropTypes.oneOf(['left', 'center', 'right']),
  customBeforeSubmit: PropTypes.func,
}
